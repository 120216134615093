import { ProIp } from "./ApiURL";


//Post Mapping :id

export const getSubjectMasterAPI = ProIp + "Subject/getSubjectMaster/";

//save class master- postMappint institute_id, class_name, stream 

export const saveSubjectMasterAPI = ProIp + "Subject/saveSubjectMaster";

export const updateSubjectMasterAPI = ProIp + "Subject/updateSubjectMaster";
