const initialData = {
    list: []
}

const LoginReducer = (state = initialData, action) => {
    switch (action.type) {
        case "ADD_USER":
            // console.log("action.payload"+action.payload.loginId)
            const { id, member_name, member_type, institute_id, institute_name } = action.payload;
            return {
                ...state,
                // list: [...state.list, { loginId: loginId, password: password }]
                list: [{ id: id, member_name: member_name, member_type: member_type, institute_id: institute_id, institute_name: institute_name }]

            }

        case "DEL_USER":
            return {
                ...state,
                list: []
            }

        default: return state
    }
}

export default LoginReducer;