import React, { useState, useEffect } from 'react'
import axios from "axios";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';

import { ImYoutube } from 'react-icons/im'
//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';
import { useNavigate } from 'react-router-dom';
//Loader
import Loader from '../CommonComponents/Loader/Loader';

// APIURL
import { getClassVideoMasterAPI, getClassroomClassMasterAPI, getClassroomSubjectMasterAPI, getClassroomChapterMasterAPI } from '../../Services/ClassroomAPIURL';


const Classroom = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "CLASSROOM" }))
    }, [])

    const authUser = useSelector((state) => state.LoginReducer.list[0]);
    const [loaderOption, setLoaderOption] = useState(false);

    const [videoId, setVideoId] = useState("");

    //Data
    const [classVideoMaster, setClassVideoMaster] = useState([]);
    const [classroomClassMaster, setClassroomClassMaster] = useState([]);
    const [classroomSubjectMaster, setClassroomSubjectMaster] = useState([]);
    const [classroomChapterMaster, setClassroomChapterMaster] = useState([]);

    //Form Data
    const [classroomClassMasterOBJ, setClassroomClassMasterOBJ] = useState(null);
    const [classroomSubjectMasterOBJ, setClassroomSubjectMasterOBJ] = useState(null);
    const [classroomChapterMasterOBJ, setClassroomChapterMasterOBJ] = useState(null);

    //Selected Data
    const [selClassVideoMaster, setSelClassVideoMaster] = useState([]);

    const [selClassroomSubjectMaster, setSelClassroomSubjectMaster] = useState([]);
    const [selClassroomChapterMaster, setSelClassroomChapterMaster] = useState([]);


    const getData = async () => {
        setLoaderOption(true);
        await axios.post(getClassVideoMasterAPI + authUser.institute_id)
            .then((res) => {
                if (res.data !== null) {
                    setClassVideoMaster(res.data);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("ClassVideoMaster Network Error");
                console.log("Error :" + err)
            })

        await axios.post(getClassroomClassMasterAPI + authUser.institute_id)
            .then((res) => {
                if (res.data !== null) {
                    setClassroomClassMaster(res.data);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("ClassroomClassMaster Network Error");
                console.log("Error :" + err)
            })

        await axios.post(getClassroomSubjectMasterAPI + authUser.institute_id)
            .then((res) => {
                if (res.data !== null) {
                    setClassroomSubjectMaster(res.data);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("ClassroomSubjectMaster Network Error");
                console.log("Error :" + err)
            })

        await axios.post(getClassroomChapterMasterAPI + authUser.institute_id)
            .then((res) => {
                if (res.data !== null) {
                    setClassroomChapterMaster(res.data);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("ClassroomChapterMaster Network Error");
                console.log("Error :" + err)
            })
        setLoaderOption(false);
    }

    useEffect(() => {
        if (authUser.institute_id) {
            getData();
        }
    }, [authUser])


    return (
        <>
            {(loaderOption) ? <Loader /> : null}
            <div className="row">
                {/* <label className="control-label col-sm-2" htmlFor="categoryM" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>Select Class :</label> */}
                <div className="col-sm-4 p-3">
                    <Autocomplete
                        id="categoryM"
                        options={classroomClassMaster}
                        value={classroomClassMasterOBJ}
                        onChange={(event, newValue) => {
                            setClassroomClassMasterOBJ(newValue);
                            setClassroomSubjectMasterOBJ(null);
                            setClassroomChapterMasterOBJ(null);
                            setSelClassroomSubjectMaster([])
                            setSelClassroomChapterMaster([])
                            if (newValue) {
                                let filterSubData = classroomSubjectMaster.filter((e) => e.class_id == newValue.id);
                                setSelClassroomSubjectMaster(filterSubData);
                            }
                            else {
                                setSelClassroomSubjectMaster([])
                            }

                        }}
                        getOptionLabel={(option) => option.class_name}
                        renderInput={(params) => <TextField {...params} label="Select Class" size='small' margin='dense' required />}

                    />
                </div>
                <div className="col-sm-4 p-3">
                    <Autocomplete
                        id="categoryM"
                        options={selClassroomSubjectMaster}
                        value={classroomSubjectMasterOBJ}
                        onChange={(event, newValue) => {
                            setClassroomSubjectMasterOBJ(newValue)
                            setSelClassroomChapterMaster([])
                            setClassroomChapterMasterOBJ(null);
                            if (newValue) {
                                let FIlterChapterData = classroomChapterMaster.filter((e) => e.subject_id == newValue.id);
                                setSelClassroomChapterMaster(FIlterChapterData);
                            }
                            else {
                                setSelClassroomChapterMaster([]);
                                setClassroomChapterMasterOBJ(null);
                            }
                        }}
                        getOptionLabel={(option) => option.subject_name}
                        renderInput={(params) => <TextField {...params} label="Select Subject" size='small' margin='dense' required />}

                    />
                </div>

                <div className="col-sm-4 p-3">
                    <Autocomplete
                        id="categoryM"
                        options={selClassroomChapterMaster}
                        value={classroomChapterMasterOBJ}
                        onChange={(event, newValue) => {
                            setClassroomChapterMasterOBJ(newValue)
                            setSelClassVideoMaster([]);
                            if (newValue) {
                                let filtersetSelClassVideoMaster = classVideoMaster.filter((e) => e.chapter_id == newValue.id);
                                setSelClassVideoMaster(filtersetSelClassVideoMaster);

                            }

                        }}
                        getOptionLabel={(option) => option.chapter_name}
                        renderInput={(params) => <TextField {...params} label="Select Chapter" size='small' margin='dense' required />}

                    />
                </div>

            </div>

            <div className="row mt-2 p-2">

                <div className="col-sm-4 col-md-4" style={{ border: "1px solid #D5D8DC" }}>
                    <div className="table-responsive" style={{ maxHeight: "400px" }}>
                        <table className='table table-bordered table-sm'>
                            <tbody>
                                {
                                    (selClassVideoMaster.length > 0) ?

                                        selClassVideoMaster.map((e, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <ImYoutube style={{ fontSize: "24px", color: "#FF0000", cursor: "pointer" }}
                                                            onClick={() => setVideoId(e.video_path)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <strong style={{ fontSize: "14px", color: "#000", cursor: "pointer" }}
                                                            onClick={() => setVideoId(e.video_path)}
                                                        >
                                                            {e.video_name}
                                                        </strong>

                                                    </td>
                                                </tr>
                                            )
                                        })

                                        : null
                                }


                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col-sm-8 col-md-8">
                    <iframe width="100%" height="400px" src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}></iframe>
                </div>

            </div>
            <ToastContainer theme='dark' position='top-right' />
        </>
    )
}

export default Classroom