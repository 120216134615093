import React, { useState, useEffect } from 'react';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination';
import { BsEyeFill, BsTrashFill, BsPencilSquare } from 'react-icons/bs';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';
import { useNavigate } from 'react-router-dom';

//Loader
import Loader from '../CommonComponents/Loader/Loader';

//Delete Modal
import DeleteModal from '../CommonComponents/DeleteModalComponent/DeleteModal';


//APIURL
import { saveClassMasterAPI, getClassMasterAPI, updateClassMasterAPI } from '../../Services/ClassMasterAPIURL';

const ClassMaster = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.LoginReducer.list[0]);
    const [loaderOption, setLoaderOption] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "CLASS MASTER" }))
    }, [])

    useEffect(() => {
        if (authUser.member_type) {
            if (authUser.member_type !== 1) {
                navigate("/")
            }
        }
    }, [authUser])



    //Data
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([])
    //console.log("data :" + JSON.stringify(data))

    //Form Data
    const [id, setId] = useState("");
    const [className, setClassName] = useState("");
    const [stream, setStream] = useState("");

    //Table

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Class Name`, key: 'class_name' },
        { label: 'Stream', key: 'stream', isAction: true },
        { label: 'Edit', key: 'edit', value: 'edit', isAction: true },
        // { label: 'Delete', key: 'del', value: 'delete', isAction: true }
    ]

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.class_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const getData = async () => {
        setLoaderOption(true);
        await axios.post(getClassMasterAPI + authUser.institute_id)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error Occured.");
                }
                else {

                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network error, Try after sometime.")
                console.log("Error : " + err)
            })
    }
    useEffect(() => {
        if (authUser.institute_id) {
            getData();
        }
    }, [authUser])

    const state = {
        button: 1
    }

    const mainFunction = (e) => {
        e.preventDefault();
        (state.button === 1) ? saveFun() : updateFun()
    }

    const saveFun = async () => {

        setLoaderOption(true);
        const saveObj = {
            "class_name": className,
            "stream": stream,
            "institute_id": authUser.institute_id
        }

        await axios.post(saveClassMasterAPI, saveObj)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error occoured, Operation failed.")
                }
                else if (res.data === "SAVED") {
                    setLoaderOption(false);
                    toast.success("Link saved sucessfully.")
                    getData();
                    clearFun()
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong, Try again")
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network Error. try again sometime");
                console.log("Error :" + err)
            })
    }

    const updateFun = async () => {
        setLoaderOption(true);
        const updateObj = {
            "class_name": className,
            "stream": stream,
            "id": id
        }

        await axios.post(updateClassMasterAPI, updateObj)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error occoured, Operation failed.")
                }
                else if (res.data === "UPDATED") {
                    setLoaderOption(false);
                    toast.success("Class updated sucessfully.")
                    getData();
                    clearFun()
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong, Try again")
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network Error. try again sometime");
                console.log("Error :" + err)
            })
    }

    const clearFun = () => {
        setClassName("");
        setId("");
        setStream("");
    }


    return (
        <>
            {(loaderOption) ? <Loader /> : null}


            <>
                <div className="row">
                    <form onSubmit={mainFunction}>

                        <div className='row'>


                            <label className="control-label col-sm-2" htmlFor="className" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>
                                Class Name:
                            </label>
                            <div className="col-sm-4">
                                <TextField id="className" label="Name of Class" variant="outlined" size='small' margin='dense' className='form-control'
                                    value={className}
                                    onChange={(e) => setClassName(e.target.value)}
                                    required
                                />
                            </div>

                            <label className="control-label col-sm-1" htmlFor="Links" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>
                                Stream :
                            </label>
                            <div className="col-sm-4">
                                <FormControl fullWidth size='small' margin='dense'>
                                    <InputLabel id="demo-simple-select-label" >Select Stream</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={stream}
                                        label="Select Stream"
                                        margin='dense'
                                        onChange={(e) => setStream(e.target.value)}
                                        required
                                    >
                                        <MenuItem value={1}>JUNIOR</MenuItem>
                                        <MenuItem value={2}>SENIOR</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>


                        </div>

                        <div className="row mt-2">
                            <div className="col-sm-4 offset-sm-2">
                                <input
                                    className={(id) ? 'btn btn-warning text-dark btn-sm' : 'btn btn-primary btn-sm'}
                                    type="submit"
                                    value={(id) ? 'UPDATE' : 'SAVE'}
                                    onClick={() => {
                                        (id) ? state.button = 2 : state.button = 1
                                    }}

                                />

                                <input className='btn btn-danger btn-sm mx-2' type="reset" value="CANCEL" onClick={clearFun} />
                            </div>
                        </div>

                    </form>
                </div>
                <hr />
            </>

            <div>

                <div className='row'>
                    <div >
                        <input
                            className='form-control form-control-sm'
                            type="text"
                            style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(event) => { setSearchTerm(event.target.value); }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="table-responsive mt-2">
                        <Table striped bordered hover size="sm" >
                            <thead className="table-Default">
                                <tr style={{ backgroundColor: "#F5E3E6", backgroundImage: "linear-gradient(200deg, #F5E3E6 10%, #D9E4F5 100%)" }}>
                                    {TableHeading.map((item, ind) => {
                                        return (
                                            <th
                                                style={{ fontSize: "12px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                                onClick={() => onSort(item.key, item.isAction)}
                                                key={ind}
                                                scope="col"
                                            >
                                                {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderBottom: "2px solid #D8EDF7", width: "100%", wordWrap: "break-word" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.id}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", wordWrap: "break-word" }}>
                                                    {item.class_name}
                                                </td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    {
                                                        (item.stream === 1) ? "JUNIOR" : "SENIOR"
                                                    }
                                                </td>

                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    <button className='btn btn-primary btn-sm p-0 px-1'
                                                        onClick={() => {
                                                            setId(item.id);
                                                            setStream(item.stream);
                                                            setClassName(item.class_name)
                                                        }}
                                                    >
                                                        <BsPencilSquare style={{ fontSize: "14px", margin: "0px" }} />
                                                    </button>
                                                </td>

                                                {/* <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    <button className='btn btn-danger btn-sm p-0 px-1'
                                                        onClick={() => { setId(item.id); setShowDeleteModal(true) }}
                                                    >
                                                        <BsTrashFill style={{ fontSize: "14px", margin: "0px" }} />
                                                    </button>
                                                </td> */}


                                            </tr>
                                        )
                                    })}
                            </tbody>
                            <tfoot >
                                <tr style={{ padding: "4px" }}>
                                    <TablePagination
                                        style={{ padding: 0, borderBottom: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        labelRowsPerPage=" Rows : "
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        //classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    />
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </div>
            </div>
            <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} />
            <ToastContainer theme='dark' position='top-right' />
        </>
    )
}

export default ClassMaster