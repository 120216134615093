import { ProIp } from "./ApiURL";


//Post Mapping :id

export const getClassMasterAPI = ProIp + "Class/getClassMaster/";

//save class master- postMappint institute_id, class_name, stream 

export const saveClassMasterAPI = ProIp + "Class/saveClassMaster";

export const updateClassMasterAPI = ProIp + "Class/updateClassMaster";
