import React from 'react'
import './masterContent.css'
import { Routes, Route } from 'react-router-dom'

import TitleLabel from '../CommonComponents/TitleLabel/TitleLabel';
import Home from '../Home/Home';
import WebOpac from '../WebOpac/WebOpac';
import KhunNLIST from '../KHub-NLIST/KhunNLIST'
import EBooks from '../EBooks/EBooks';
import QPapers from '../QPapers/QPapers';
import Syllabus from '../Syllabus/Syllabus';
import Faq from '../FAQ/Faq';
import Links from '../Links/Links';
import MemberReport from '../MemberReport/MemberReport';
import UserCreation from '../UserCreation/UserCreation';
import CategoryMaster from '../CategoryMaster/CategoryMaster';
import ChangePassword from '../ChangePassword/ChangePassword';
import VisitorCount from '../VisitorCount/VisitorCount';
import ClassMaster from '../ClassMaster/ClassMaster';
import SubjectMaster from '../SubjectMaster/SubjectMaster';
import ChapterMaster from '../ChapterMaster/ChapterMaster';
import VideoManagement from '../VideoManagement/VideoManagement';
import Classroom from '../Classroom/Classroom';


const MasterContent = () => {
    return (
        <div className='masterWrapper'>
            <div className="container p-0 ">
                <TitleLabel titleLable={"titleLable"} />
            </div>
            <div className='container innerBox'>
                <Routes>
                    <Route index path='/' element={<Home />} />
                    <Route path='/n001' element={<WebOpac />} />
                    <Route path='/n002' element={<KhunNLIST />} />
                    <Route path='/n003' element={<EBooks />} />
                    <Route path='/n004' element={<QPapers />} />
                    <Route path='/n005' element={<Syllabus />} />
                    <Route path='/n006' element={<Faq />} />
                    <Route path='/n007' element={<Links />} />
                    <Route path='/n009' element={<Classroom />} />

                    <Route path='/n081' element={<MemberReport />} />
                    <Route path='/n080' element={<UserCreation />} />
                    <Route path='/n082' element={<CategoryMaster />} />
                    <Route path='/n083' element={<VisitorCount />} />

                    <Route path='/n084' element={<ClassMaster />} />
                    <Route path='/n085' element={<SubjectMaster />} />
                    <Route path='/n086' element={<ChapterMaster />} />
                    <Route path='/n087' element={<VideoManagement />} />

                    <Route path='/n011' element={<ChangePassword />} />



                    <Route index path='*' element={<Home />} />
                </Routes>
            </div>
        </div>
    )
}

export default MasterContent