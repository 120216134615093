import React, { useState, useEffect } from 'react';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Badge from 'react-bootstrap/Badge';
import TablePagination from '@mui/material/TablePagination';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import moment from 'moment/moment';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';

//APIURL
import { getMemberApi, updateStatusAPI, deleteMemberAPI } from '../../Services/MemberAPIURL';

//Loader
import Loader from '../CommonComponents/Loader/Loader';
import DeleteModal from '../CommonComponents/DeleteModalComponent/DeleteModal';

const MemberReport = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.LoginReducer.list[0]);
    const [loaderOption, setLoaderOption] = useState(false);


    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "MEMBER REPORT" }))
    }, [])

    useEffect(() => {
        if (authUser.member_type) {
            if (authUser.member_type !== 1) {
                navigate("/")
            }
        }
    }, [authUser])


    //Data
    const [masterData, setMasterData] = useState([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([])

    //Form Fields
    const [id, setId] = useState("");
    const [status, setStatus] = useState("");
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);


    //Table
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const TableHeading = [
        { label: 'SR.NO', key: 'id' },
        { label: `Member Code`, key: 'member_code' },
        { label: `Member Name`, key: 'member_name' },
        { label: 'Member Type', key: 'member_type' },
        { label: 'Mail Id', key: 'mail_id' },
        { label: 'Class / Designation', key: 'class_name' },
        { label: 'Joining Date', key: 'joining_date' },
        { label: 'Status', key: 'status', isAction: true },
        { label: 'Action', key: 'action', value: 'action', isAction: true }
    ]

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.member_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);


    const getData = async () => {
        setLoaderOption(true);
        await axios.post(getMemberApi + authUser.institute_id)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error Occured.");
                }
                else {

                    setData(res.data);
                    setFilteredData(res.data);
                    setMasterData(res.data);
                    setLoaderOption(false);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network error, Try after sometime.")
                console.log("Error : " + err)
            })
    }

    useEffect(() => {
        if (authUser.institute_id) {
            getData();
        }
    }, [authUser])


    const updateStatusFun = async (e) => {
        setShowUpdateModal(false)
        e.preventDefault();
        const updateObj = {
            "id": id,
            "status": status
        }
        await axios.post(updateStatusAPI, updateObj)
            .then((res) => {
                if (res.data === "ERROR") {
                    toast.error("Error occured, Try again");
                }
                else if (res.data === "UPDATED") {
                    getData();
                    toast.success("Status updated sucessfully.");
                }
            })
            .catch((err) => {
                toast.error("Network error, Try after sometime.");
                console.log("Error : " + err)
            })

    }

    const dataSetting = (status) => {
        // alert(status)

        if (status === 0) {
            setData([].concat(masterData))
            setFilteredData([].concat(masterData))
        }
        else {
            let tempData = masterData.filter((e) => e.status == status);
            setData([].concat(tempData))
            setFilteredData([].concat(tempData))
        }
    }

    const deleteFun = async () => {
        setShowDeleteModal(false);
        const delObj = {
            "id": id,
            "institute_id": authUser.institute_id
        }
        axios.post(deleteMemberAPI, delObj)
            .then((res) => {
                if (res.data === "DELETED") {
                    clearFun()
                    getData();
                    toast.success("Member Deleted Sucessfully.")
                }
                else {
                    clearFun()
                    toast.error("Operation failed, something went wrong.")
                }
            })
            .catch((err) => {
                clearFun()
                console.log("Error Member Deletion : " + err)
                toast.error("Error occured.")
            })
    }

    const clearFun = () => {
        setId("");
    }
    return (
        <>
            {(loaderOption) ? <Loader /> : null}



            <div>
                <div className="row p-2">
                    <div className="col-sm-12">
                        <Button variant="primary" style={{ width: "160px", margin: "4px" }} onClick={() => dataSetting(0)} size="sm">
                            All <Badge pill bg="light" text="dark"> {masterData.length} </Badge>
                        </Button>{' '}

                        <Button variant="success" style={{ width: "160px", margin: "4px" }} onClick={() => dataSetting(1)} size="sm">
                            Approved <Badge pill bg="light" text="dark"> {masterData.filter((e) => e.status == 1).length} </Badge>
                        </Button>{' '}
                        <Button variant="warning" style={{ width: "160px", margin: "4px" }} onClick={() => dataSetting(2)} size="sm">
                            Pending <Badge pill bg="light" text="dark"> {masterData.filter((e) => e.status == 2).length} </Badge>
                        </Button>{' '}
                        <Button variant="danger" style={{ width: "160px", margin: "4px" }} onClick={() => dataSetting(3)} size="sm">
                            Blocked <Badge pill bg="light" text="dark"> {masterData.filter((e) => e.status == 3).length} </Badge>
                        </Button>{' '}
                    </div>
                </div>

                <div className='row'>
                    <div >
                        <input
                            className='form-control form-control-sm'
                            type="text"
                            style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(event) => { setSearchTerm(event.target.value); }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="table-responsive mt-2">
                        <Table striped bordered hover size="sm" >
                            <thead className="table-Default">
                                <tr style={{ backgroundColor: "#F5E3E6", backgroundImage: "linear-gradient(200deg, #F5E3E6 10%, #D9E4F5 100%)" }}>
                                    {TableHeading.map((item, ind) => {
                                        return (
                                            <th
                                                style={{ fontSize: "12px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                                onClick={() => onSort(item.key, item.isAction)}
                                                key={ind}
                                                scope="col"
                                            >
                                                {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderBottom: "2px solid #D8EDF7", width: "100%", wordWrap: "break-word" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.id}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.member_code}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.member_name}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>

                                                    {(() => {
                                                        if (item.member_type == 3) {
                                                            return <Badge pill bg="info" text='dark'>STUDENT</Badge>
                                                        }
                                                        else if (item.member_type == 4) {
                                                            return <Badge pill bg="warning" text="dark">OTHER</Badge>
                                                        }
                                                        else if (item.member_type == 2) {
                                                            return <Badge pill bg="danger">TEACHER</Badge>
                                                        }
                                                    })()}
                                                </td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.mail_id}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.class_name}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    {
                                                        moment(item.joining_date).format("DD-MM-YYYY")
                                                    }
                                                </td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>


                                                    {(() => {
                                                        if (item.status == 1) {
                                                            return <Badge pill bg="success">APPROVED</Badge>
                                                        }
                                                        else if (item.status == 2) {
                                                            return <Badge pill bg="warning" text="dark">PENDING</Badge>
                                                        }
                                                        else if (item.status == 3) {
                                                            return <Badge pill bg="danger">BLOCKED</Badge>
                                                        }
                                                    })()}
                                                </td>


                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    <button className='btn btn-primary btn-sm p-0 px-1'
                                                        onClick={() => {
                                                            setId(item.id);
                                                            setStatus(item.status);
                                                            setShowUpdateModal(true);
                                                        }}
                                                    >
                                                        <FaEdit style={{ fontSize: "14px", margin: "0px" }} />
                                                    </button>

                                                    <button className='btn btn-danger btn-sm p-0 px-1 mx-2'
                                                        onClick={() => {
                                                            setId(item.id);
                                                            setShowDeleteModal(true);
                                                        }}
                                                    >
                                                        <FaTrashAlt style={{ fontSize: "14px", margin: "0px" }} />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                            <tfoot >
                                <tr style={{ padding: "4px" }}>
                                    <TablePagination
                                        style={{ padding: 0, borderBottom: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        labelRowsPerPage=" Rows : "
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        //classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    />
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </div>
            </div>

            <Modal
                show={showUpdateModal}
                onHide={() => { setShowUpdateModal(false) }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                {/* <Modal.Header closeButton className='modalHead'>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header> */}

                <Modal.Body>
                    <Form onSubmit={updateStatusFun}>
                        <Form.Group className="mb-3">
                            <Form.Label><strong>Select Status</strong></Form.Label>
                            <Form.Select value={status} onChange={(e) => setStatus(e.target.value)} required>
                                <option value={""} disabled>Select Option</option>
                                <option value={1}>Approve</option>
                                <option value={2}>Pending</option>
                                <option value={3}>Block</option>
                            </Form.Select>
                        </Form.Group>

                        <Button type='submit' size='sm' variant="primary">UPDATE</Button>
                        <Button onClick={() => { setShowUpdateModal(false) }} size='sm' style={{ marginLeft: "8px" }} variant='danger'>CLOSE</Button>
                    </Form>
                </Modal.Body>

            </Modal>
            <ToastContainer theme='dark' position='top-right' />
            <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deleteFun={deleteFun} />
        </>
    )
}

export default MemberReport