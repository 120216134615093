import React, { useState, useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';


//Loader
import Loader from '../CommonComponents/Loader/Loader';

//APIURL
import { createMemberAPI } from '../../Services/MemberAPIURL';
import { getClassMasterAPI } from '../../Services/ClassMasterAPIURL';
import axios from 'axios';


const UserCreation = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.LoginReducer.list[0]);
    const [loaderOption, setLoaderOption] = useState(false);


    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "USER CREATION" }))
    }, [])

    useEffect(() => {
        if (authUser.member_type) {
            if (authUser.member_type !== 1) {
                navigate("/")
            }
        }
    }, [authUser])


    const [classData, setClassData] = useState([]);

    //FOrm Field
    const [member_code, setMember_code] = useState("");
    const [member_name, setMember_name] = useState("");
    const [member_type, setMember_type] = useState("");
    const [mobile_no, setMobile_no] = useState("");
    const [mail_id, setMail_id] = useState("");
    const [class_name, setClass_name] = useState("");
    const [joining_date, setJoining_date] = useState("");
    const [status, setStatus] = useState(1);
    const [password, setPassword] = useState("");
    const [institute_id, setInstitute_id] = useState(authUser.institute_id);
    const [register_type, setRegister_type] = useState(1);
    const [designation, setDesignation] = useState("");

    const clearFun = () => {
        setMember_code("");
        setMember_name("");
        setMember_type("");
        setMobile_no("");
        setMail_id("");
        setClass_name("");
        setJoining_date("");
        setStatus(1);
        setPassword("");
        setInstitute_id(authUser.institute_id);
        setRegister_type(1);
        setDesignation("");
    }

    const createUserFun = async (e) => {
        e.preventDefault();
        const saveObj = {
            "member_code": (member_type === "2" || member_type === "4") ? 0 : member_code,
            "member_name": member_name,
            "member_type": member_type,
            "mobile_no": mobile_no,
            "mail_id": mail_id,
            "class_name": (member_type === "2" || member_type === "4") ? "" : class_name,
            "joining_date": joining_date,
            "status": status,
            "password": password,
            "institute_id": institute_id,
            "register_type": register_type,
            "designation": (member_type === 3) ? "" : designation
        }
        //console.log(saveObj)
        await axios.post(createMemberAPI, saveObj)
            .then((res) => {
                if (res.data === "ERROR") {
                    toast.error("Error occured, try again later.");
                }
                else if (res.data === "SAVED") {
                    clearFun();
                    toast.success("User created sucessfully.")
                }
                else if (res.data === "EXISTS") {
                    toast.warn("Account already exists")
                }
                else {
                    console.log(res.data)
                }
            })
            .catch((err) => {
                toast.error("Error occured, something went wrong.");
                console.log("Error:" + err);
            })

    }

    const getClassMaster = async () => {
        await axios.post(getClassMasterAPI + authUser.institute_id)
            .then((res) => {
                if (res.data === "ERROR") {
                    toast.error("Class Master Error.")
                }
                else {
                    setClassData(res.data);
                }
            })
            .catch((err) => {
                toast.error("Error occured")
                console.log("Error : " + err)
            })
    }

    useEffect(() => {
        if (authUser.institute_id) {
            getClassMaster();
            setInstitute_id(authUser.institute_id);
        }
    }, [authUser])

    return (
        <div style={{ padding: "16px" }}>
            <Form onSubmit={createUserFun}>

                <div className="row">
                    <div className="col-sm-6">

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Member Type :</Form.Label>
                            <Form.Select as="select" defaultValue={""} value={member_type}
                                onChange={e => setMember_type(e.target.value)} required>
                                <option value={""} disabled>Select Type</option>
                                <option value={3}>Student</option>
                                <option value={2}>Teacher</option>
                                <option value={4}>Other</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Member Name :</Form.Label>
                            <Form.Control type="text" placeholder="Member Name" required

                                value={member_name}
                                onChange={(e) => setMember_name(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Mobile No :</Form.Label>
                            <Form.Control type="text" placeholder="Mobile No" required
                                value={mobile_no}
                                onChange={(e) => setMobile_no(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Email Id :</Form.Label>
                            <Form.Control type="email" placeholder="Email Id" required
                                value={mail_id}
                                onChange={(e) => setMail_id(e.target.value)}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-sm-6">
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password :</Form.Label>
                            <Form.Control type="password" placeholder="Password" required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Admission Date/Joining Date :</Form.Label>
                            <Form.Control type="Date" placeholder="Select Date" required
                                value={joining_date}
                                onChange={(e) => setJoining_date(e.target.value)}
                            />
                        </Form.Group>

                        {
                            (member_type === "2" || member_type === "4")
                                ?
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Designation :</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Designation" required
                                        value={designation}
                                        onChange={(e) => setDesignation(e.target.value)}
                                    />
                                </Form.Group>
                                :
                                null
                        }

                        {
                            (member_type === "3")
                                ?
                                <>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>College Registration Number :</Form.Label>
                                        <Form.Control type="text" placeholder="Enter College Reg No" required
                                            value={member_code}
                                            onChange={(e) => setMember_code(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Class :</Form.Label>
                                        <Form.Select as="select" required
                                            value={class_name}
                                            onChange={(e) => setClass_name(e.target.value)}
                                        >
                                            <option value={""} selected disabled>Select Class</option>
                                            {
                                                (classData.length > 0) ?

                                                    classData.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.class_name}>
                                                                {item.class_name}
                                                            </option>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </>
                                :
                                null
                        }

                    </div>
                </div>




                <Button type='submit' size='sm' variant="primary" >
                    SUBMIT
                </Button>
                <Button onClick={() => { clearFun() }} size='sm' style={{ marginLeft: "8px" }} variant='danger'>CLOSE</Button>



            </Form>
            <ToastContainer position='top-right' theme='dark' />
        </div>
    )
}

export default UserCreation