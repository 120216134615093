import React, { useState, useEffect } from 'react';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import TablePagination from '@mui/material/TablePagination';
import { BsPencilSquare } from 'react-icons/bs';

//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';
import { useNavigate } from 'react-router-dom';

//Loader
import Loader from '../CommonComponents/Loader/Loader';

//Delete Modal
import DeleteModal from '../CommonComponents/DeleteModalComponent/DeleteModal';


//APIURL
import { getClassMasterAPI } from '../../Services/ClassMasterAPIURL';
import { getSubjectMasterAPI } from '../../Services/SubjectMasterAPIURL';
import { getChapterMasterAPI, saveChapterMasterAPI, updateChapterMasterAPI } from '../../Services/ChapterMasterAPIURL';
import { getVideoManagementAPI, saveVideoManagementAPI, updateVideoManagementAPI } from '../../Services/VideoManagementAPIURL';



const VideoManagement = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.LoginReducer.list[0]);
    const [loaderOption, setLoaderOption] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "VIDEO MANAGEMENT" }))
    }, [])

    useEffect(() => {
        if (authUser.member_type) {
            if (authUser.member_type !== 1) {
                navigate("/")
            }
        }
    }, [authUser])



    //Data
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([])

    const [classData, setClassData] = useState([]);

    const [subjectData, setSubjectData] = useState([]);
    const [selectedSubData, setSelectedSubData] = useState([])

    const [chapterData, setChapterData] = useState([]);
    const [selectedChapterData, setSelectedChapterData] = useState([])
    //console.log("data :" + JSON.stringify(data))

    //Form Data
    const [id, setId] = useState("");
    const [classObj, setClassObj] = useState(null);
    const [subjectObj, setSubjectObj] = useState(null);
    const [chapterObj, seChapterObj] = useState(null)
    const [videoName, setVideoName] = useState("");
    const [videoPath, setVideoPath] = useState("");

    const [chapterName, setChapterName] = useState("");
    

    //Table

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Class Name`, key: 'class_name' },
        { label: `Subject Name`, key: 'subject_name' },
        { label: `Chapter Name`, key: 'chapter_name' },
        { label: `Video Name`, key: 'video_name', isAction: true },
        { label: 'Edit', key: 'edit', value: 'edit', isAction: true },
        // { label: 'Delete', key: 'del', value: 'delete', isAction: true }
    ]

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.class_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.subject_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.chapter_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const getData = async () => {
        setLoaderOption(true);
        await axios.post(getClassMasterAPI + authUser.institute_id)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error Occured.");
                }
                else {

                    setClassData(res.data);
                    setLoaderOption(false);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network error, Try after sometime.")
                console.log("Error : " + err)
            })

        await axios.post(getSubjectMasterAPI + authUser.institute_id)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error Occured.");
                }
                else {

                    setSubjectData(res.data);
                    setLoaderOption(false);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network error, Try after sometime.")
                console.log("Error : " + err)
            })

        await axios.post(getChapterMasterAPI + authUser.institute_id)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error Occured.");
                }
                else {

                    setChapterData(res.data);
                    setLoaderOption(false);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network error, Try after sometime.")
                console.log("Error : " + err)
            })

        await axios.post(getVideoManagementAPI + authUser.institute_id)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error Occured.");
                }
                else {

                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network error, Try after sometime.")
                console.log("Error : " + err)
            })
    }
    useEffect(() => {
        if (authUser.institute_id) {
            getData();
        }
    }, [authUser])

    const state = {
        button: 1
    }

    const mainFunction = (e) => {
        e.preventDefault();
        (state.button === 1) ? saveFun() : updateFun()
    }

    const saveFun = async () => {

        //id, institute_id, class_id, subject_id, chapter_id, video_name, video_path

        setLoaderOption(true);
        const saveObj = {
            "class_id": classObj.id,
            "subject_id": subjectObj.id,
            "institute_id": authUser.institute_id,
            "chapter_id": chapterObj.id,
            "video_name": videoName,
            "video_path": videoPath
        }

        await axios.post(saveVideoManagementAPI, saveObj)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error occoured, Operation failed.")
                }
                else if (res.data === "SAVED") {
                    setLoaderOption(false);
                    toast.success("Video saved sucessfully.")
                    getData();
                    clearFun()
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong, Try again")
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network Error. try again sometime");
                console.log("Error :" + err)
            })
    }

    const updateFun = async () => {
        setLoaderOption(true);

        //id, institute_id, class_id, subject_id, chapter_id, video_name, video_path
        const updateObj = {
            "class_id": classObj.id,
            "subject_id": subjectObj.id,
            "institute_id": authUser.institute_id,
            "chapter_id": chapterObj.id,
            "video_name": videoName,
            "video_path": videoPath,
            "id": id
        }


        await axios.post(updateVideoManagementAPI, updateObj)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error occoured, Operation failed.")
                }
                else if (res.data === "UPDATED") {
                    setLoaderOption(false);
                    toast.success("Video updated sucessfully.")
                    getData();
                    clearFun()
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong, Try again")
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network Error. try again sometime");
                console.log("Error :" + err)
            })
    }

    const clearFun = () => {
        setClassObj(null);
        setId("");
        setSubjectObj(null);

        setSelectedSubData([])
        setSelectedChapterData([])
        seChapterObj(null)

        setVideoName("");
        setVideoPath("");
    }

    const setModifyData = (idd, clIdd, sIdd, chIdd, vname, vpath) => {

        //item.id, item.class_id, item.subject_id, item.chapter_name

        setId(idd);
        setVideoName(vname);
        setVideoPath(vpath);

        let filClassData = classData.filter((e) => e.id == clIdd);
        let createClassObj = {
            "id": filClassData[0].id,
            "institute_id": filClassData[0].institute_id,
            "class_name": filClassData[0].class_name,
            "stream": filClassData[0].stream
        }
        setClassObj(createClassObj)


        let filSubjectData = subjectData.filter((e) => e.id == sIdd);
        let createSubjectObj = {
            //id, institute_id, class_id, subject_name
            "id": filSubjectData[0].id,
            "institute_id": filSubjectData[0].institute_id,
            "class_id": filSubjectData[0].class_id,
            "subject_name": filSubjectData[0].subject_name
        }
        setSubjectObj(createSubjectObj)

        let filChapterData = chapterData.filter((e) => e.id == chIdd);
        let createChapterObj = {
            //id, institute_id, class_id, subject_id, chapter_name
            "id": filChapterData[0].id,
            "institute_id": filChapterData[0].institute_id,
            "class_id": filChapterData[0].class_id,
            "subject_id": filChapterData[0].subject_id,
            "chapter_name": filChapterData[0].chapter_name
        }
        seChapterObj(createChapterObj)

        let filterSelSubData = subjectData.filter((e) => e.class_id === clIdd);
        setSelectedSubData(filterSelSubData);


        let filterSelChapterData = chapterData.filter((e) => e.subject_id === sIdd);
        setSelectedChapterData(filterSelChapterData);

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }


    return (
        <>
            {(loaderOption) ? <Loader /> : null}


            <>
                <div className="row">
                    <form onSubmit={mainFunction}>

                        <div className='row'>
                            <label className="control-label col-sm-2" htmlFor="Links" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>
                                Select Class:
                            </label>
                            <div className="col-sm-4">
                                <Autocomplete
                                    value={classObj}
                                    fullWidth
                                    onChange={(event, newValue) => {
                                        setClassObj(newValue);
                                        setSubjectObj(null);
                                        seChapterObj(null);
                                        setSelectedChapterData([]);
                                        if (newValue) {
                                            let filterData = subjectData.filter((e) => e.class_id === newValue.id);
                                            setSelectedSubData(filterData);
                                        }
                                        else {
                                            setSelectedSubData([]);
                                            setSelectedChapterData([]);
                                        }
                                    }}
                                    id="combo-box-demo"
                                    options={classData}
                                    getOptionLabel={(option) => option.class_name}

                                    renderInput={(params) => <TextField {...params} label="Select Class" margin='dense' size='small' required />}
                                />
                            </div>


                            <label className="control-label col-sm-2" htmlFor="Links" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>
                                Select Subject:
                            </label>
                            <div className="col-sm-4">
                                <Autocomplete
                                    value={subjectObj}
                                    onChange={(event, newValue) => {
                                        setSubjectObj(newValue);
                                        seChapterObj(null);

                                        if (newValue) {
                                            let filterData = chapterData.filter((e) => e.subject_id === newValue.id);
                                            setSelectedChapterData(filterData);
                                        }
                                        else {
                                            setSelectedChapterData([]);
                                        }
                                    }}
                                    id="combo-box-demo"
                                    fullWidth
                                    options={selectedSubData}
                                    getOptionLabel={(option) => option.subject_name}

                                    renderInput={(params) => <TextField {...params} label="Select Subject" margin='dense' size='small' required />}
                                />
                            </div>

                            <label className="control-label col-sm-2" htmlFor="className" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>
                                Select Chapter:
                            </label>
                            <div className="col-sm-4">
                                <Autocomplete
                                    value={chapterObj}
                                    onChange={(event, newValue) => {
                                        seChapterObj(newValue);
                                    }}
                                    id="combo-box-demo"
                                    fullWidth
                                    options={selectedChapterData}
                                    getOptionLabel={(option) => option.chapter_name}

                                    renderInput={(params) => <TextField {...params} label="Select Chapter" margin='dense' size='small' required />}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <label className="control-label col-sm-2" htmlFor="className" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>
                                Video Name:
                            </label>
                            <div className="col-sm-4">
                                <TextField id="className" label="Name of Video" variant="outlined" size='small' margin='dense' fullWidth
                                    value={videoName}
                                    onChange={(e) => setVideoName(e.target.value)}
                                    required
                                />
                            </div>

                            <label className="control-label col-sm-2" htmlFor="className" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>
                                Video Path:
                            </label>
                            <div className="col-sm-4">
                                <TextField id="className" label="Youtube Video ID" variant="outlined" size='small' margin='dense' fullWidth
                                    value={videoPath}
                                    onChange={(e) => setVideoPath(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-sm-4 offset-sm-2">
                                <input
                                    className={(id) ? 'btn btn-warning text-dark btn-sm' : 'btn btn-primary btn-sm'}
                                    type="submit"
                                    value={(id) ? 'UPDATE' : 'SAVE'}
                                    onClick={() => {
                                        (id) ? state.button = 2 : state.button = 1
                                    }}

                                />

                                <input className='btn btn-danger btn-sm mx-2' type="reset" value="CANCEL" onClick={clearFun} />
                            </div>
                        </div>

                    </form>
                </div>
                <hr />
            </>

            <div>

                <div className='row'>
                    <div >
                        <input
                            className='form-control form-control-sm'
                            type="text"
                            style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(event) => { setSearchTerm(event.target.value); }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="table-responsive mt-2">
                        <Table striped bordered hover size="sm" >
                            <thead className="table-Default">
                                <tr style={{ backgroundColor: "#F5E3E6", backgroundImage: "linear-gradient(200deg, #F5E3E6 10%, #D9E4F5 100%)" }}>
                                    {TableHeading.map((item, ind) => {
                                        return (
                                            <th
                                                style={{ fontSize: "12px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                                onClick={() => onSort(item.key, item.isAction)}
                                                key={ind}
                                                scope="col"
                                            >
                                                {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderBottom: "2px solid #D8EDF7", width: "100%" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.id}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    {item.class_name}
                                                </td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    {item.subject_name}
                                                </td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    {item.chapter_name}
                                                </td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    {item.video_name}
                                                </td>


                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    <button className='btn btn-primary btn-sm p-0 px-1'
                                                        onClick={() => setModifyData(item.id, item.class_id, item.subject_id, item.chapter_id, item.video_name, item.video_path)}
                                                    >
                                                        <BsPencilSquare style={{ fontSize: "14px", margin: "0px" }} />
                                                    </button>
                                                </td>

                                                {/* <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    <button className='btn btn-danger btn-sm p-0 px-1'
                                                        onClick={() => { setId(item.id); setShowDeleteModal(true) }}
                                                    >
                                                        <BsTrashFill style={{ fontSize: "14px", margin: "0px" }} />
                                                    </button>
                                                </td> */}


                                            </tr>
                                        )
                                    })}
                            </tbody>
                            <tfoot >
                                <tr style={{ padding: "4px" }}>
                                    <TablePagination
                                        style={{ padding: 0, borderBottom: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        labelRowsPerPage=" Rows : "
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        //classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    />
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </div>
            </div>
            <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} />
            <ToastContainer theme='dark' position='top-right' />
        </>
    )
}

export default VideoManagement