import React from 'react'
import './loader.css';

const Loader = () => {
    return (
        <>
            <div className="loaderLocation">
                <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
           
        </>
    )
}

export default Loader