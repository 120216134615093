import { ProIp } from "./ApiURL";


//Post Mapping :id

export const getChapterMasterAPI = ProIp + "Chapter/getChapterMaster/";

//save class master- postMappint institute_id, class_name, stream 

export const saveChapterMasterAPI = ProIp + "Chapter/saveChapterMaster";

export const updateChapterMasterAPI = ProIp + "Chapter/updateChapterMaster";
