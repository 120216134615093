import React, { useEffect } from 'react';
import imageNlist from "./nlist.png";
import { TbHandClick } from "react-icons/tb"


//Redux
import { useDispatch, useSelector } from 'react-redux';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';

const KhunNLIST = () => {

    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.LoginReducer.list[0]);

    useEffect(() => {
        if (authUser.institute_id) {
            if (authUser.institute_id === 2) {
                dispatch(titleLabelAction({ titleLabel: "MANUPATRA" }))
            }
            else {
                dispatch(titleLabelAction({ titleLabel: "N-LIST" }))
            }
        }
    }, [authUser])




    if (authUser.institute_id) {
        if (authUser.institute_id === 2) {
            return (
                <>
                    <div className="row">
                        <div className="col-sm-3">
                            <img src="https://www.manupatrafast.com/Resources/images/home/2021/logo.png" alt="" className='img-fluid' />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3" style={{ padding: "8px", marginTop: "10px" }}>
                            <h3 className='text-primary'> </h3>
                            <table className='table' style={{borderColor:"#641E16"}}>
                                <thead style={{ backgroundColor: "#641E16", color: "#fff" }}>
                                    <tr>
                                        <th colSpan={3}> Login Credentials</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <th>Login Id</th>
                                        <th>:</th>
                                        <td>nsslawtemp1</td>
                                    </tr>
                                    <tr>
                                        <th>Password</th>
                                        <th>:</th>
                                        <td>NSS@2021</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div>
                                <a href="https://www.manupatrafast.com/" target="_blank" rel="noopener noreferrer"
                                    className='form-control' style={{ padding: "8px", backgroundColor: "#5B2C6F", color: "#fff", cursor: "pointer", borderRadius: "4px", fontSize: "12px", fontWeight: "600", textDecoration: "none" }}
                                >
                                    <TbHandClick size={"18px"} /> Click here to visit "MANUPATRA"
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else {
            return (
                <>

                    <a href="https://nlist.inflibnet.ac.in" target="_blank" rel="noopener noreferrer">
                        <img src={imageNlist} alt="" className='img-fluid'
                            style={{ userSelect: "none" }}
                        />
                    </a>

                </>
            )
        }
    }

}

export default KhunNLIST