import { ProIp } from "./ApiURL";


//Post Mapping :id

export const getVideoManagementAPI = ProIp + "Video/getVideoManagement/";

//save class master- postMappint institute_id, class_name, stream 

export const saveVideoManagementAPI = ProIp + "Video/saveVideoManagement";

export const updateVideoManagementAPI = ProIp + "Video/updateVideoManagement";
