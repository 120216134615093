import { ProIp } from "./ApiURL";


//postMapping :id === institute_id
export const getClassVideoMasterAPI = ProIp + "Classroom/getClassVideoMaster/";

export const getClassroomClassMasterAPI = ProIp + "Classroom/getClassroomClassMaster/";

export const getClassroomSubjectMasterAPI = ProIp + "Classroom/getClassroomSubjectMaster/";

export const getClassroomChapterMasterAPI = ProIp + "Classroom/getClassroomChapterMaster/";


