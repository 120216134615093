import { ProIp } from "./ApiURL";

//postMapping :id

export const getMemberApi = ProIp + "Members/getMembers/";

//postMapping

export const updateStatusAPI = ProIp + "Members/updateStatus";

//PostMapping Create Member

export const createMemberAPI = ProIp + "Members/createMembers"


//PostMapping Delete Member

export const deleteMemberAPI = ProIp + "Members/deleteMembers"